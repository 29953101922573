import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FineTuning as Component } from './components/FineTuningTab';
import {
  FineTuneParams,
  FineTuningCompDetails,
  FineTuningCounts,
  Rejections,
  UpdateRejectionStatusParams
} from './FineTuning.proptype';
import { FetchLoadingStateEnum } from '../../../../constants';
import { PbaJurisdictionPliFormat, RangeComparables } from '../../../../models';
import {
  fetchFineTuningCompanies,
  fetchFineTuningCompany,
  fetchInitialRangeComparables,
  fetchRejectionReasons,
  updateRejectionReason,
  updateRejectionRowStatus,
  fetchFineTuningFinancialInformationCompany,
  fetchFineTuningPLICompany,
  fetchJurisdictionPliFormats,
  clearSelectedCompDetails,
  fetchInitialRangeComparablesWithRejections
} from '../../../../redux/profitBasedAnalyses';
import {
  fineTuningCounts,
  selectCurrenctFineTuningCompany,
  selectFineTuningCompaniesWithYearlyAvgs,
  selectInitialRangeComparables,
  selectJurisdictionPliFormat,
  selectRejectionReasons,
  selectWorkingContainer,
  selectFinancialInfoCompany,
  selectPliInfoCompany,
  selectFineTuningCompaniesPending,
  selectFineTuningCompanies,
  selectInitialRangeComparablesWithRejections,
  selectPBALoadingState
} from '../../../../selectors';
import { logGoogleAnalyticsEvent } from '../../../../utils/sendGoogleAnalyticaEvent';
import { StatusTypes } from '../../../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';

const filterFineTuningCompsYearly = (
  fineTuningCompsYearlyData: {
    data: any;
    columns: any[];
  },
  selectedFilter: StatusTypes
) => {
  if (selectedFilter === 'total') {
    return fineTuningCompsYearlyData.data;
  }

  const result = fineTuningCompsYearlyData.data.filter((comp: any) => {
    if (selectedFilter === 'accepted') {
      return comp.compStatus === 'global.accepted';
    }

    if (selectedFilter === 'acceptedAndReviewed') {
      return comp.compStatus === 'global.accepted_and_reviewed';
    }

    if (selectedFilter === 'rejected') {
      return comp.compStatus === 'global.rejected';
    }

    return true;
  });

  return result;
};

const Connector = () => {
  const flags = useFlags();
  const container = useSelector(selectWorkingContainer);
  const compSearchId = Number(useParams<{ compSearchId: string }>().compSearchId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const pliTypeId = Number(useParams<{ pliId: string }>().pliId);
  const pliAvgTypeId = Number(useParams<{ pliAvgId: string }>().pliAvgId);
  const jurisdictionPliFormat: PbaJurisdictionPliFormat[] = useSelector(selectJurisdictionPliFormat(jurisdictionId));
  const pliFormat = jurisdictionPliFormat
    ? jurisdictionPliFormat.find((jurisdictionPli) => jurisdictionPli.pliTypeId === pliTypeId)
    : null;

  const dispatch = useDispatch();
  const fineTuningCompaniesPending = useSelector(selectFineTuningCompaniesPending);

  useEffect(() => {
    if (!jurisdictionPliFormat) {
      void dispatch(fetchJurisdictionPliFormats({ compSearchId, jurisdictionId }));
    }
  }, [compSearchId, dispatch, jurisdictionId, jurisdictionPliFormat]);

  const rejectionReasons: Rejections[] | undefined = useSelector(selectRejectionReasons);
  const currentSelectedCompany: FineTuningCompDetails | undefined = useSelector(selectCurrenctFineTuningCompany);
  const fineTuningStatusCounts: FineTuningCounts = useSelector(fineTuningCounts);
  const yearly = useSelector(selectInitialRangeComparables);
  const fineTuningCompsYearly = useSelector(selectFineTuningCompaniesWithYearlyAvgs);
  const fineTuningCompanies = useSelector(selectFineTuningCompanies);
  const initialComparables: RangeComparables | undefined = useSelector(selectInitialRangeComparablesWithRejections);
  const financialInfo = useSelector(selectFinancialInfoCompany);
  const pliInfo = useSelector(selectPliInfoCompany);

  const [isFistTime, setIsFirstTime] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState<StatusTypes>('total');

  const params: FineTuneParams = useMemo(() => {
    return { compSearchId, jurisdictionId, pliTypeId, pliAvgTypeId };
  }, [compSearchId, jurisdictionId, pliAvgTypeId, pliTypeId]);

  useEffect(() => {
    setIsFirstTime(true);
    dispatch(fetchFineTuningCompanies({ params: { compSearchId, jurisdictionId, pliTypeId, pliAvgTypeId } }));
  }, [dispatch, compSearchId, jurisdictionId, pliTypeId, pliAvgTypeId]);

  useEffect(() => {
    if (compSearchId && jurisdictionId && pliTypeId && pliAvgTypeId && !initialComparables) {
      void dispatch(
        fetchInitialRangeComparablesWithRejections({
          compSearchId,
          jurisdictionId,
          pliId: pliTypeId,
          pliAvgId: pliAvgTypeId,
          useS3Flag: flags.getRangeComparables
        })
      );
    }
  }, [dispatch, initialComparables, compSearchId, jurisdictionId, pliTypeId, pliAvgTypeId, flags]);

  const pbaLoadingState = useSelector(selectPBALoadingState);
  const fetchInitialRangeComparablesLoadingState =
    pbaLoadingState.fetchInitialRangeComparables ?? FetchLoadingStateEnum.reload;
  const fetchRejectionReasonsLoadingState = pbaLoadingState.fetchRejectionReasons ?? FetchLoadingStateEnum.reload;
  useEffect(
    () => {
      if (
        !yearly &&
        ![FetchLoadingStateEnum.fulfilled, FetchLoadingStateEnum.loading].includes(
          fetchInitialRangeComparablesLoadingState
        )
      ) {
        dispatch(
          fetchInitialRangeComparables({
            compSearchId,
            jurisdictionId,
            pliId: pliTypeId,
            pliAvgId: pliAvgTypeId,
            useS3Flag: flags.getRangeComparables
          })
        );
      }

      if (
        !rejectionReasons &&
        ![FetchLoadingStateEnum.fulfilled, FetchLoadingStateEnum.loading].includes(fetchRejectionReasonsLoadingState)
      ) {
        dispatch(fetchRejectionReasons({ params }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getFineTuningCompanyDetails = useCallback(
    (companyId: number, forceUpdate = false) => {
      if (companyId === currentSelectedCompany?.sourceId && !forceUpdate) return;
      dispatch(clearSelectedCompDetails());
      dispatch(fetchFineTuningCompany({ params: { ...params, companyId } }));
      dispatch(fetchFineTuningFinancialInformationCompany({ params: { ...params, companyId } }));
      dispatch(fetchFineTuningPLICompany({ params: { ...params, companyId } }));
    },
    [dispatch, params, currentSelectedCompany]
  );

  useEffect(() => {
    if (!currentSelectedCompany && fineTuningCompsYearly.data.length > 0 && isFistTime) {
      setIsFirstTime(false);
      getFineTuningCompanyDetails(fineTuningCompsYearly.data[0].sourceId);
    }
  }, [currentSelectedCompany, fineTuningCompsYearly, isFistTime, getFineTuningCompanyDetails]);

  useEffect(() => {
    /* eslint-disable camelcase */
    void logGoogleAnalyticsEvent({
      event_category: 'pba_button_click',
      event_label: `Filter clicked for ${selectedFilter}`,
      container_id: container?.containerId
    });
  }, [selectedFilter, container?.containerId]);

  const isUpdateRejectionStatusForceUpdate = (
    fineTuning: any,
    currentSelectCompany: FineTuningCompDetails | undefined,
    rejectionType: string | undefined
  ) => {
    if (fineTuning.otherRejection !== currentSelectCompany?.otherRejection) return true; // If other rejection changes, needs to be updated
    return (
      currentSelectedCompany?.isRejected !==
        fineTuning.isRejected /* The rejection type selected is different at the current one */ ||
      currentSelectedCompany?.rejectionName !==
        rejectionType /* The rejection type name is different at the current one (only when company is rejected again) */
    );
  };

  const updateRejectionStatus = async ({
    statuses,
    rejectionType,
    otherRejectionReason,
    selectedCompaniesSourceId
  }: UpdateRejectionStatusParams) => {
    const baseUrl = `compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/pli/${params.pliTypeId}/pli-avg/${params.pliAvgTypeId}`;
    const fineTuningUpdate: any = {
      sourceId: currentSelectedCompany?.sourceId,
      baseUrl,
      compSearchId: params.compSearchId,
      rejectionTypeId: currentSelectedCompany?.rejectionTypeId ?? 0,
      ...statuses
    };

    if (rejectionType) {
      const rejectionObject: any = (rejectionReasons ?? []).find((rejection: any) => {
        return rejection.rejectionName === rejectionType;
      });
      if (rejectionObject.rejectionName === 'rejection_type.other') {
        fineTuningUpdate.otherRejection = otherRejectionReason;
      }

      Object.assign(fineTuningUpdate, rejectionObject);
    }

    const sourceIds =
      selectedCompaniesSourceId && selectedCompaniesSourceId.length > 0
        ? selectedCompaniesSourceId
        : [currentSelectedCompany?.sourceId];

    if (
      isUpdateRejectionStatusForceUpdate(fineTuningUpdate, currentSelectedCompany, rejectionType) &&
      sourceIds.length > 0
    ) {
      const data = {
        containerId: container?.containerId,
        ...fineTuningUpdate,
        pliTypeId: params.pliTypeId,
        pliAverageTypeId: params.pliAvgTypeId,
        sourceIds
      };

      await dispatch<any>(updateRejectionReason({ ...params, data }));
      sourceIds.forEach((sourceId) => {
        dispatch(
          updateRejectionRowStatus({
            sourceId,
            compStatus: {
              isAcceptedAndReviewed: statuses.isAcceptedAndReviewed,
              isRejected: statuses.isRejected
            }
          })
        );
      });
    }

    getFineTuningCompanyDetails(
      fineTuningUpdate?.sourceId,
      isUpdateRejectionStatusForceUpdate(
        fineTuningUpdate,
        currentSelectedCompany,
        rejectionType
      ) /* forcing right panel update */
    );

    dispatch(fetchFineTuningCompanies({ params: { compSearchId, jurisdictionId, pliTypeId, pliAvgTypeId } }));
  };

  const fineTuningRejections: Rejections[] = (rejectionReasons ?? []).filter(
    (rejection: any) => rejection.rejectionGroupName === 'rejection_group_type.fine_tuning'
  );

  const filteredFineTuningCompsYearly = useMemo(() => {
    const result = {
      ...fineTuningCompsYearly,
      data: filterFineTuningCompsYearly(fineTuningCompsYearly, selectedFilter)
    };

    if (!fineTuningCompsYearly || fineTuningCompsYearly?.data.length < 1) {
      return result;
    }

    if (
      (selectedFilter === 'accepted' &&
        (currentSelectedCompany?.acceptedAndReviewed === 1 || currentSelectedCompany?.isRejected)) ||
      (selectedFilter === 'rejected' && !currentSelectedCompany?.isRejected) ||
      (selectedFilter === 'acceptedAndReviewed' &&
        (currentSelectedCompany?.acceptedAndReviewed !== 1 || currentSelectedCompany?.isRejected))
    ) {
      const companyId = result.data[0].sourceId;
      dispatch(fetchFineTuningCompany({ params: { ...params, companyId } }));
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fineTuningCompsYearly, selectedFilter]);

  return (
    <Component
      {...filteredFineTuningCompsYearly}
      comparables={fineTuningCompanies}
      fineTuningStatusCounts={fineTuningStatusCounts}
      rejectionReasons={fineTuningRejections}
      updateRejectionStatus={updateRejectionStatus}
      currentSelectedCompany={currentSelectedCompany}
      financialInformation={financialInfo}
      pliInfo={pliInfo}
      pliFormat={pliFormat}
      pliId={pliTypeId}
      selectedFilter={selectedFilter}
      fineTuningCompaniesPending={fineTuningCompaniesPending}
      onClickRow={getFineTuningCompanyDetails}
      onSetSelectedFilter={setSelectedFilter}
    />
  );
};

export default Connector;
