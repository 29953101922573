import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompSearchState } from '@xbs/xbs-enums';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { PliStatusUpdate } from './profitBasedAnalyses.proptype';
import { RootState } from '..';
import { PbaLocation } from '../../app/NewAnalysisSideMenu/AnalysisSideMenu.proptype';
import { SearchPinnedComparablesCompaniesParams } from '../../app/NewAnalysisSideMenu/InitialSearch/components/AddPinnedComparableModal/AddPinnedComparableModal.proptype';
import { ParentContainerCompPoolInfo } from '../../app/NewAnalysisSideMenu/InitialSearch/InitialSearch.proptype';
import { SheetFinancialData } from '../../app/PBADashboardFinancials/PBADashboardBalanceSheet/PBADashboardBalanceSheet.proptype';
import {
  PBASummarySubmitData,
  TestedPartyProps
} from '../../app/PBADashboardTPDSummary/PBADashboardTPDSummary.proptype';
import { PliValues, PliAverages } from '../../app/ProfitLevelIndicator/ProfitLevelIndicator.proptype';
import { IndustrialCodeValue } from '../../app/TestedPartyDistributor';
import {
  FineTuneParams,
  FineTuningCompanies,
  FineTuningCompanyParams,
  FineTuningCompDetails,
  Rejections,
  RejectionStatusPayload
} from '../../app/TuneCompSearch/components/FineTuning/FineTuning.proptype';
import {
  AreaIds,
  FunctionalAnalysisCharacteristic,
  UpdateFunctionalAnalysisNarrativeParams
} from '../../components/FunctionalAnalysis/FunctionalAnalysisTable.proptype';
import { FetchLoadingState, FetchLoadingStateEnum } from '../../constants';
import {
  ProfitBasedAnalysis,
  newProfitBasedAnalysis,
  TestedParty,
  ApplyUnappliedBulkRejectionParams,
  ApplyUnappliedBulkRejectionResponse,
  BulkRejectionsParams,
  Container,
  PbaJurisdiction,
  JurisdictionDescription,
  PbaJurisdictionPliFormat,
  PbaJurisdictionPliFormatPayload,
  BulkRejection,
  RangeParams,
  PbaJurisdictionPayload,
  CompSearchStatus,
  CompSearchRunBothPayload,
  RangeResults,
  RangeDiscussion,
  RangeDiscussionParams,
  RangeComparables,
  PbaJurisdictionStatus,
  FineTuningPLIInfo,
  FineTuningFinancialInfo,
  ProjectCompPoolPayload,
  CompSearchAnalysisTemplate,
  CompSearchPsgAnalysisTemplate,
  NewGapAnalysisResponse
} from '../../models';
import { FinancialData, FinancialDataObjectTypeEnum } from '../../models/financial.interface';
import {
  AddManualComparableCompanyPayload,
  CompSearchLoadPsgAnalysisTemplatePayload,
  GapAnalysisParams,
  GapAnalysisResponse,
  ManualComparablesCompany,
  PinnedCompsResponse,
  SavedPsgAnalysisTemplates,
  ProjectCompPoolWithoutCompSearchPayload
} from '../../models/profitBasedAnalyses.interface';
import httpService from '../../services/http';
import { roundPliValues } from '../../utils/numbers';
import { getWorkingContainer } from '../baseData';

interface PBAsState {
  currentPBA?: ProfitBasedAnalysis;
  currentTestedParty?: TestedPartyProps;
  pbas: ProfitBasedAnalysis[] | null;
  oldTestedPartyFinancials: FinancialData[] | null;
  balanceSheetFinancials: FinancialData[] | null;
  incomeStatementFinancials: FinancialData[] | null;
  pbaPli?: {
    pliValues: PliValues[];
    pliAverages: PliAverages[];
  };
  pbaJurisdiction: PbaJurisdiction[] | null;
  initialRangeComparablesWithRejections?: RangeComparables;
  appliedBulkRejections?: BulkRejection[] | null;
  nonAppliedBulkRejections?: BulkRejection[] | null;
  initialRangeComparables?: RangeComparables;
  initialRangeResults?: RangeResults;
  finalRangeComparables?: RangeComparables;
  finalRangeResults?: RangeResults;
  finalRangeDiscussion?: RangeDiscussion;
  gapAnalysis?: GapAnalysisResponse;
  newGapAnalysis?: NewGapAnalysisResponse;
  pinnedComps?: PinnedCompsResponse;
  error?: string;
  csStatus?: number;
  fineTuningCompanies?: any;
  rejectionReasons?: Rejections[] | undefined;
  selectedFineTuningCompany?: FineTuningCompDetails;
  financialInfoCompany?: FineTuningFinancialInfo[];
  pliInfoCompany?: FineTuningPLIInfo;
  additionalFinancialInfo?: string;
  savedPsgAnalysisTemplates?: SavedPsgAnalysisTemplates[];
  fineTuningCompaniesPending: boolean;
  finalRangeCompaniesPending: boolean;
  functionalAnalysisCharacteristics:
    | {
        Functions: FunctionalAnalysisCharacteristic[];
        Risks: FunctionalAnalysisCharacteristic[];
        Assets: FunctionalAnalysisCharacteristic[];
      }
    | undefined;
  analysisTemplates?: CompSearchAnalysisTemplate[];
  manualComparablesCompanies?: ManualComparablesCompany[];
  allAvailableManualComparablesCompanies?: ManualComparablesCompany[] | null;
  parentContainerCompPoolInfo: ParentContainerCompPoolInfo | Record<string, Record<string, number>>;
  loading: {
    fetchRejectionReasons?: FetchLoadingStateEnum;
    fetchInitialRangeComparables?: FetchLoadingStateEnum;
    fetchJurisdictionPlis?: FetchLoadingState;
    fetchCurrentTestedParty?: FetchLoadingState;
    fetchPBAs?: FetchLoadingState;
    fetchPBAJurisdictionDetails?: FetchLoadingState;
    fetchTestedPartyFinancials?: FetchLoadingState;
    fetchRoyaltyStatTransactions?: FetchLoadingState;
    updateTestedPartyFinancial?: FetchLoadingState;
    fetchPBAJurisdictionDescription?: {
      [jurisdictionId: string]: FetchLoadingState;
    };
    fetchPBAJurisdictionPLIFormats?: FetchLoadingState;
    fetchPBAPli?: FetchLoadingState;
    fetchParentContainerCompPoolRunOnDate?: FetchLoadingState;
    fetchJurisdictionRules?: FetchLoadingState;
    fetchManualComparablesCompanies?: FetchLoadingState;
    updateJurisdictionsForManualComparablesCompany?: {
      [key: number]: FetchLoadingState;
    };
    fetchGetJurisdictionRules?: {
      [key: number]: {
        [key: number]: FetchLoadingState;
      };
    };
  };
  csJurisdictionRunId?: number | null;
  jurisdictionRules: JurisdictionRules;
}

const initialState: PBAsState = {
  pbas: null,
  newGapAnalysis: {
    comps: [],
    previousTaxYear: 0,
    currentTaxYear: 0,
    totalAcceptedCompsPerYear: {}
  },
  gapAnalysis: {
    previousYearCompPool: [],
    currentYearCompPool: []
  },
  pinnedComps: {
    data: [
      {
        containerId: 0,
        jurisdictionId: 0,
        sourceId: 0,
        sourceType: 0,
        createdAt: ''
      }
    ]
  },
  oldTestedPartyFinancials: null,
  incomeStatementFinancials: null,
  balanceSheetFinancials: null,
  pbaJurisdiction: null,
  appliedBulkRejections: null,
  nonAppliedBulkRejections: null,
  additionalFinancialInfo: '',
  fineTuningCompaniesPending: false,
  finalRangeCompaniesPending: false,
  functionalAnalysisCharacteristics: {
    Functions: [],
    Risks: [],
    Assets: []
  },
  parentContainerCompPoolInfo: {},
  csJurisdictionRunId: null,
  loading: {
    fetchPBAJurisdictionDescription: {}
  },
  jurisdictionRules: {}
};

interface PliData {
  pliAverages: PliAverages[];
  pliValues: PliValues[];
}

interface FetchJurisdictionRulesParams {
  pbaId: number;
  jurisdictionId: number;
  taxYear: number;
}

interface FetchJurisdictionRulesResponse {
  name: string;
  locationId: number;
  locationTypeId: number;
  mustUseLocalComps: number;
  preference: number;
  pbaPrimaryJurisdictionId: number;
}

const pbaFunctionalAnalysisTypeId = 3;

export const fetchTestedPartyFinancialsInfo = createAsyncThunk<string, number, { rejectValue: Error }>(
  'testedParty/financials-info/fetch',
  async (testedPartyId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: string }>({
          method: 'get',
          apiUrlKey: 'tpCoreApiUrl',
          relativePath: `/v1/tested-party/${testedPartyId}/financials-info`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const updateTestedPartyFinancialsInfo = createAsyncThunk<
  any,
  {
    container: Container;
    financialsInfo: string;
    testedPartyId: number;
  },
  { rejectValue: Error }
>('testedParty/financials-info/post', async ({ container, financialsInfo, testedPartyId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: any }>({
        method: 'post',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/tested-party/${testedPartyId}/financials-info`,
        data: { container, financialsInfo }
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchPBAsApiCall = async (studyId: number) => {
  const {
    data: { data }
  } = await httpService.request<{ data: ProfitBasedAnalysis[] }>({
    method: 'get',
    apiUrlKey: 'baseUrl',
    relativePath: `pbas/study/${studyId}`,
    params: { sort: 'name', order: 'asc' }
  });
  return data;
};

export const fetchPBAs = createAsyncThunk<ProfitBasedAnalysis[], number, { rejectValue: Error }>(
  'pbas/fetch',
  async (studyId, { rejectWithValue }) => {
    try {
      return await fetchPBAsApiCall(studyId);
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const updateFunctionalAnalysisNarrative = createAsyncThunk<
  any,
  { params: UpdateFunctionalAnalysisNarrativeParams },
  { rejectValue: Error }
>('functional-analysis/copy', async ({ params }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: any }>({
        method: 'put',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/copy/${String(params.studyId)}/type/${params.type}`,
        data: { ...params }
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchFunctionalAnalysisCharacteristics = createAsyncThunk<
  FunctionalAnalysisCharacteristic[],
  { functionalAnalysisId: number; areaId: number },
  { rejectValue: Error }
>(`functional-analysis/area/type/fetch`, async ({ functionalAnalysisId, areaId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: FunctionalAnalysisCharacteristic[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/${functionalAnalysisId}/area/${areaId}/type/${pbaFunctionalAnalysisTypeId}`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const createFunctionalAnalysisCharacteristic = createAsyncThunk<
  FunctionalAnalysisCharacteristic,
  {
    functionalAnalysisId: number;
    areaId: number;
    characteristic: { characteristicName: string; container: Container | undefined };
  },
  { rejectValue: Error }
>(
  'functional-analysis/area/type/post',
  async ({ functionalAnalysisId, areaId, characteristic }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: FunctionalAnalysisCharacteristic }>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath: `functional-analysis/${functionalAnalysisId}/characteristic/area/${areaId}/type/${pbaFunctionalAnalysisTypeId}`,
          data: characteristic
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const updateFunctionalAnalysisCharacteristic = createAsyncThunk<
  number,
  {
    characteristicId: number;
    characteristic: Partial<FunctionalAnalysisCharacteristic>;
  },
  { rejectValue: Error }
>(
  'functional-analysis/characteristic/type/patch',
  async ({ characteristicId, characteristic }, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/characteristic/${characteristicId}/type/${pbaFunctionalAnalysisTypeId}`,
        data: characteristic
      });
      return characteristicId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const reinitiaizeToFionaDefualts = createAsyncThunk<
  void,
  {
    functionalAnalysisId: number;
  },
  { rejectValue: Error }
>('functional-analysis//type/reinitialize', async ({ functionalAnalysisId }, { rejectWithValue }) => {
  try {
    await httpService.request({
      method: 'post',
      apiUrlKey: 'baseUrl',
      relativePath: `functional-analysis/${functionalAnalysisId}/reinitialize/type/${pbaFunctionalAnalysisTypeId}?overwrite=default`
    });
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const deleteFunctionalAnalysisCharacteristic = createAsyncThunk<number, number, { rejectValue: Error }>(
  'functional-analysis/characteristic/type/delete',
  async (characteristicId, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/characteristic/${characteristicId}/type/${pbaFunctionalAnalysisTypeId}`
      });
      return characteristicId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchTestedPartyFinancials = createAsyncThunk<
  { data: FinancialData[]; financialTypeId: number },
  { testedPartyId: number; financialTypeId: number },
  { rejectValue: Error }
>('testedParty/financial/fetch', async ({ testedPartyId, financialTypeId }, { rejectWithValue }) => {
  try {
    const response = await httpService.request<{ data: FinancialData[] }>({
      method: 'get',
      apiUrlKey: 'baseUrl',
      relativePath: `financial-data/${testedPartyId}/area/${financialTypeId}/type/${FinancialDataObjectTypeEnum.TESTED_PARTY}`
    });
    return {
      data: response.data.data,
      financialTypeId
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchPBAPli = createAsyncThunk<PliData, number, { rejectValue: Error }>(
  'pbas/tested-party/pli',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: PliData }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/tested-party/pli`,
          params: { sort: 'name', order: 'asc' }
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchCurrentTestedParty = createAsyncThunk<TestedPartyProps, number, { rejectValue: Error }>(
  'current-tested-party/fetch',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: TestedPartyProps }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `pbas/${pbaId}/tested-party`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchJurisdictionRules = createAsyncThunk<
  FetchJurisdictionRulesResponse[],
  FetchJurisdictionRulesParams,
  { rejectValue: Error }
>('jurisdiction-rules/fetch', async (params: FetchJurisdictionRulesParams, { rejectWithValue }) => {
  try {
    const { pbaId, jurisdictionId, taxYear } = params;
    const relativePath = `/v1/pba/${pbaId}/jurisdiction/${jurisdictionId}/taxYear/${taxYear}/locations`;

    const { data } = await httpService.request<{ data: FetchJurisdictionRulesResponse[] }>({
      method: 'get',
      apiUrlKey: 'compSearchApiUrl',
      relativePath
    });

    return data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

// fetch ALL companies available to be added to a pba
export const searchManualComparablesCompanies = createAsyncThunk<
  ManualComparablesCompany[],
  SearchPinnedComparablesCompaniesParams,
  { rejectValue: Error }
>('manual-comparable-companies/fetchAllAvailable', async (params, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: ManualComparablesCompany[] }>({
        method: 'post',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: '/v1/pba/search-comps-post',
        data: params
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

// fetch companies currently added to pba
export const fetchManualComparablesCompanies = createAsyncThunk<
  ManualComparablesCompany[],
  {
    pbaId: number;
    runOnDate: string;
  },
  { rejectValue: Error }
>('manual-comparable-companies/fetch', async ({ pbaId, runOnDate }, { rejectWithValue }) => {
  const relativePath = `/v1/pba/${pbaId}/pinned-comps?runOnDate=${runOnDate}`;

  try {
    return (
      await httpService.request<{ data: ManualComparablesCompany[] }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const addManualComparablesCompany = createAsyncThunk<
  { company: AddManualComparableCompanyPayload; pbaId: number },
  { company: AddManualComparableCompanyPayload; pbaId: number },
  { rejectValue: Error }
>('manual-comparable-companies/add', async ({ pbaId, company }, { rejectWithValue }) => {
  try {
    await httpService.request<{ data: ManualComparablesCompany }>({
      method: 'put',
      apiUrlKey: 'compSearchApiUrl',
      relativePath: `/v1/pba/${String(pbaId)}/pinned-comps/${company.sourceId}`,
      data: company
    });
    return { company, pbaId };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updateJurisdictionsForManualComparablesCompany = createAsyncThunk<
  { sourceId: number; jurisdictionIds: number[] },
  { pbaId: number; sourceId: number; containerId: number; jurisdictionIds: number[]; sourceType: number | undefined }, // fix: no undefined
  { rejectValue: Error }
>(
  'manual-comparable-companies-pinned-jurisdictions/update',
  async ({ pbaId, sourceId, containerId, jurisdictionIds, sourceType }, { rejectWithValue }) => {
    try {
      await httpService.request<{ data: { containerId: number; jurisdictionIds: number[]; sourceType: number } }>({
        method: 'put',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/pba/${pbaId}/pinned-comps/${sourceId}`,
        data: { containerId, jurisdictionIds, sourceType }
      });
      return { sourceId, jurisdictionIds };
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const removeManualComparablesCompany = createAsyncThunk<
  { sourceId: number; pbaId: number },
  { sourceId: number; pbaId: number },
  { rejectValue: Error }
>('manual-comparable-companies/delete', async ({ pbaId, sourceId }, { rejectWithValue }) => {
  try {
    await httpService.request<{ data: ManualComparablesCompany[] }>({
      method: 'delete',
      apiUrlKey: 'compSearchApiUrl',
      relativePath: `/v1/pba/${pbaId}/pinned-comps/${sourceId}`
    });
    return { sourceId, pbaId };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const deletePBA = createAsyncThunk<number, number, { rejectValue: Error }>(
  'pbas/delete',
  async (pbaId, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `pbas/${pbaId}`
      });
      return pbaId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deletePBAAssociatedTransaction = createAsyncThunk<
  number,
  { pbaId: number; transactionId: number },
  { rejectValue: Error }
>('pbasAssociatedTransaction/delete', async ({ pbaId, transactionId }, { rejectWithValue }) => {
  try {
    await httpService.request<number>({
      method: 'delete',
      apiUrlKey: 'baseUrl',
      relativePath: `pbas/${pbaId}/transactions/${transactionId}`
    });
    return transactionId;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const savePBA = createAsyncThunk<
  ProfitBasedAnalysis,
  { pba: Partial<ProfitBasedAnalysis> | newProfitBasedAnalysis; t: TFunction },
  { state: RootState; rejectValue: Error }
>('pbas/save', async ({ pba, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const isEdit = typeof (pba as Partial<ProfitBasedAnalysis>).pbaId === 'number';
    const relativePath = isEdit ? `pbas/${(pba as Partial<ProfitBasedAnalysis>).pbaId!}` : 'pbas';
    const { data } = await httpService.request<{ data: ProfitBasedAnalysis }>({
      method: isEdit ? 'patch' : 'post',
      apiUrlKey: 'baseUrl',
      relativePath,
      data: pba
    });

    return data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updatePbaJurisdictionStatus = createAsyncThunk<
  number,
  { pbaId: number; jurisdictionStatus: PbaJurisdictionStatus },
  { rejectValue: Error }
>('pbas/jurisdiction-status/update', async ({ pbaId, jurisdictionStatus }, { rejectWithValue }) => {
  try {
    await httpService.request({
      method: 'patch',
      apiUrlKey: 'baseUrl',
      relativePath: `pbas/${pbaId}/jurisdiction-status`,
      data: jurisdictionStatus
    });
    return pbaId;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const applyUnappliedBulkRejection = createAsyncThunk<
  ApplyUnappliedBulkRejectionResponse,
  ApplyUnappliedBulkRejectionParams,
  { rejectValue: Error }
>('applied-bulk-rejections/applyUnapply', async (data: ApplyUnappliedBulkRejectionParams, { rejectWithValue }) => {
  try {
    const endPath = data.isApplying ? 'apply' : 'unapply';
    const relativePath = `/v1/compsearch/${data.compSearchId}/jurisdiction/${data.jurisdictionId}/pli/${data.pliId}/pli-avg/${data.pliAvgId}/bulk-rejections/${data.rejectionTypeId}/${endPath}`;
    await httpService.request<{ data: any }>({
      method: 'put',
      apiUrlKey: 'compSearchApiUrl',
      relativePath,
      data
    });

    return {
      rejectionTypeId: data.rejectionTypeId,
      isApplying: data.isApplying
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchAppliedBulkRejections = createAsyncThunk<
  BulkRejection[],
  BulkRejectionsParams,
  { rejectValue: Error }
>('applied-bulk-rejections/fetch', async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: BulkRejection[] }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/bulk-rejections/applied`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchNonAppliedBulkRejections = createAsyncThunk<
  BulkRejection[],
  BulkRejectionsParams,
  { rejectValue: Error }
>(
  'non-applied-bulk-rejections/fetch',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: BulkRejection[] }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/bulk-rejections/non-applied`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchPbaJurisdictions = createAsyncThunk<PbaJurisdictionPayload, number, { rejectValue: Error }>(
  'pba-jurisdictions/fetch',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: any }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/pba/${pbaId}/jurisdictions`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchInitialRangeComparables = createAsyncThunk<RangeComparables, RangeParams, { rejectValue: Error }>(
  'range-comparables/initial',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId, useS3Flag }, { rejectWithValue }) => {
    try {
      if (useS3Flag) {
        const data = await httpService.request<any>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-comparables/initial`
        });

        const signedUrl = data.data.data.url;
        const response = await httpService.requestWithoutAuthHeader<RangeComparables>(signedUrl);
        return response.data;
      }

      return (
        await httpService.request<{ data: RangeComparables }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-comparables/initial`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchInitialRangeComparablesWithRejections = createAsyncThunk<
  RangeComparables,
  RangeParams,
  { rejectValue: Error }
>(
  'range-comparables/initial-rejection',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId, useS3Flag }, { rejectWithValue }) => {
    try {
      if (useS3Flag) {
        const data = await httpService.request<any>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-comparables/initial-rejection`
        });

        const signedUrl = data.data.data.url;
        const response = await httpService.requestWithoutAuthHeader<RangeComparables>(signedUrl);
        return response.data;
      }

      return (
        await httpService.request<{ data: RangeComparables }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-comparables/initial-rejection`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchInitialRangeResults = createAsyncThunk<RangeResults, RangeParams, { rejectValue: Error }>(
  'range-results/initial',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: RangeResults }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-results/initial`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFinalRangeComparables = createAsyncThunk<RangeComparables, RangeParams, { rejectValue: Error }>(
  'range-comparables/final/fetch',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId, useS3Flag }, { rejectWithValue }) => {
    try {
      if (useS3Flag) {
        const data = await httpService.request<any>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-comparables/final`
        });

        const signedUrl = data.data.data.url;
        const response = await httpService.requestWithoutAuthHeader<RangeComparables>(signedUrl);
        return response.data;
      }

      return (
        await httpService.request<{ data: RangeComparables }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-comparables/final`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFinalRangeResults = createAsyncThunk<RangeResults, RangeParams, { rejectValue: Error }>(
  'range-results/final',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: RangeResults }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-results/final`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFinalRangeDiscussion = createAsyncThunk<RangeDiscussion, RangeParams, { rejectValue: Error }>(
  'range-discussion/fetch',
  async ({ compSearchId, jurisdictionId, pliId, pliAvgId }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: RangeDiscussion }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-discussion`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchGapAnalysis = createAsyncThunk<GapAnalysisResponse, GapAnalysisParams, { rejectValue: Error }>(
  'gap-analysis/fetch',
  async (args, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: { body: GapAnalysisResponse } }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${args.compSearchId}/jurisdiction/${args.jurisdictionId}/pli/${args.pliId}/pli-avg/${args.pliAvgId}/pba/${args.pbaId}/gap-analysis`
        })
      ).data.data.body;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const fetchNewGapAnalysis = createAsyncThunk<NewGapAnalysisResponse, GapAnalysisParams, { rejectValue: Error }>(
  'new-gap-analysis/fetch',
  async (args, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: { body: NewGapAnalysisResponse } }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v2/compsearch/${args.compSearchId}/jurisdiction/${args.jurisdictionId}/pli/${args.pliId}/pli-avg/${args.pliAvgId}/pba/${args.pbaId}/gap-analysis`
        })
      ).data.data.body;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error);
      }

      return rejectWithValue(new Error('Something went wrong'));
    }
  }
);

export const saveFinalRangeDiscussion = createAsyncThunk<any, RangeDiscussionParams, { rejectValue: Error }>(
  'range-discussion/save',
  async (
    { compSearchId, jurisdictionId, pliId, pliAvgId, container, containerId, rangeDiscussion },
    { rejectWithValue }
  ) => {
    try {
      return (
        await httpService.request<{ data: RangeDiscussion }>({
          method: 'put',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/pli/${pliId}/pli-avg/${pliAvgId}/range-discussion`,
          data: { container, containerId, rangeDiscussion }
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchJurisdictionDescription = createAsyncThunk<
  JurisdictionDescription,
  { compSearchId: number; jurisdictionId: number },
  { rejectValue: Error }
>('compSearch/jurisdiction-description/fetch', async ({ compSearchId, jurisdictionId }, { rejectWithValue }) => {
  try {
    const fetchDescription = httpService.request<{ data: any }>({
      method: 'get',
      apiUrlKey: 'compSearchApiUrl',
      relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/description`
    });
    const fetchRuleOverride = httpService.request<{ data: any }>({
      method: 'get',
      apiUrlKey: 'compSearchApiUrl',
      relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/rule-override`
    });
    const promises = [fetchDescription, fetchRuleOverride];
    const response = await Promise.all(promises);
    const compPoolDescription = response[0].data.data.data.compPoolDescription.data;
    const jurisdictionRuleOverrideInfo = response[1].data.data;
    const searchResults = { ...compPoolDescription, ...jurisdictionRuleOverrideInfo };

    return searchResults;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchJurisdictionPlis = createAsyncThunk<
  PbaJurisdictionPayload,
  { compSearchId: number; jurisdictionId: number },
  { rejectValue: Error }
>('compSearch/jurisdiction-pli/fetch', async ({ compSearchId, jurisdictionId }, { rejectWithValue }) => {
  try {
    return {
      data: (
        await httpService.request<{ data: PbaJurisdictionPayload }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/plis`
        })
      ).data.data.data.evaluationInstances,
      jurisdictionId
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchJurisdictionPliFormats = createAsyncThunk<
  { data: PbaJurisdictionPliFormat[] },
  { compSearchId: number; jurisdictionId: number },
  { rejectValue: Error }
>('compSearch/jurisdiction-pli-formats/fetch', async ({ compSearchId, jurisdictionId }, { rejectWithValue }) => {
  try {
    return {
      data: (
        await httpService.request<PbaJurisdictionPliFormatPayload>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/get-jurisdiction-pli-formats`
        })
      ).data.data
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

interface UpdatePliStatusParams {
  compSearchId: number;
  jurisdictionId: number;
  pliTypeId: number;
  pliAverageTypeId: number;
  pliStatus: number;
  currentContainer: Container | undefined;
}

export const updatePliStatus = createAsyncThunk<void, UpdatePliStatusParams, { rejectValue: Error }>(
  'compSearch/pli-status/update',
  async (data: UpdatePliStatusParams, { rejectWithValue }) => {
    try {
      const relativePath = `/v1/compsearch/${data.compSearchId}/jurisdiction/${data.jurisdictionId}/pli/${data.pliTypeId}/pli-avg/${data.pliAverageTypeId}/pli-status`;
      await httpService.request<{ data: any }>({
        method: 'put',
        apiUrlKey: 'compSearchApiUrl',
        relativePath,
        data
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const currentPBA = createAction('currentPBA', (pba: ProfitBasedAnalysis) => {
  return { payload: pba };
});

export const editJurisdictionPliStatus = createAction(
  'editJurisdictionPliStatus',
  (jurisdictionId: number, pliIndex: number, exclude: string) => {
    return {
      payload: {
        jurisdictionId,
        pliIndex,
        exclude
      }
    };
  }
);

interface EditTestedPartyParams {
  pbaId: number;
  testedParty: PBASummarySubmitData;
}

export const editTestedParty = createAsyncThunk<void, EditTestedPartyParams, { rejectValue: Error }>(
  'pbas/editTestedParty',
  async ({ pbaId, testedParty }, { rejectWithValue }) => {
    try {
      const relativePath = `pbas/${pbaId}/tested-party`;
      await httpService.request<{ data: TestedParty }>({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath,
        data: {
          pba: { pbaId },
          ...testedParty
        }
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const createTestedParty = createAsyncThunk<void, EditTestedPartyParams, { rejectValue: Error }>(
  'pbas/createTestedParty',
  async ({ pbaId, testedParty }, { rejectWithValue }) => {
    try {
      const relativePath = `pbas/${pbaId}/tested-party`;
      await httpService.request<{ data: TestedParty }>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath,
        data: testedParty
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const runCompSearch = createAsyncThunk<CompSearchStatus, CompSearchRunBothPayload, { rejectValue: Error }>(
  'pbas/compSearch/run-compSearch/post',
  async ({ pbaId, compSearchPayload }, { rejectWithValue }) => {
    try {
      const relativePath = `/v1/pba/${pbaId}/run-compsearch`;
      const { data } = await httpService.request<{ data: CompSearchStatus }>({
        method: 'post',
        apiUrlKey: 'compSearchApiUrl',
        relativePath,
        data: compSearchPayload
      });

      return data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchCompSearchStatus = createAsyncThunk<CompSearchStatus, number, { rejectValue: Error }>(
  'pbas/compSearch-status/fetch',
  async (pbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: CompSearchStatus }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/pba/${pbaId}/compsearch/status`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const saveAnalysisTemplate = createAsyncThunk<void, CompSearchAnalysisTemplate, { rejectValue: Error }>(
  'pbas/compSearch/save-comp-search',
  async (payload, { rejectWithValue }) => {
    try {
      const relativePath = `/v1/tenant/${payload.tenantId}/save-comp-search`;
      await httpService.request<{ data: any }>({
        method: 'post',
        apiUrlKey: 'compSearchApiUrl',
        relativePath,
        data: payload
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const savePsgAnalysisTemplate = createAsyncThunk<void, CompSearchPsgAnalysisTemplate, { rejectValue: Error }>(
  'pbas/compSearch/comp-search-global-library/save',
  async (payload, { rejectWithValue }) => {
    try {
      const relativePath = '/v1/comp-search-global-library/save';
      await httpService.request<{ data: any }>({
        method: 'post',
        apiUrlKey: 'compSearchApiUrl',
        relativePath,
        data: payload
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchSavedAnalysisTemplates = createAsyncThunk<
  CompSearchAnalysisTemplate[],
  number,
  { rejectValue: Error }
>('pbas/compSearch/get-comp-search', async (tenantId, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: CompSearchAnalysisTemplate[] }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/tenant/${tenantId}/get-comp-searches`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchSavedPsgAnalysisTemplates = createAsyncThunk<
  SavedPsgAnalysisTemplates[],
  void,
  { rejectValue: Error }
>('pbas/compSearch/comp-search-global-library/get-saved', async (_, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: SavedPsgAnalysisTemplates[] }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: '/v1/comp-search-global-library/get-saved'
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export interface LoadAnalysisTemplatePayload {
  compSearchName: string;
  container: Container;
  loadCompSearchId: number;
  loadContainerId: number;
  loadJurisdictionId: number;
  tenantId: number;
}

export interface LoadAnalysisTemplateParams {
  compSearchId: number;
  jurisdictionId: number;
  containerId: number;
}

interface LoadAnalysisTemplate {
  payload: LoadAnalysisTemplatePayload;
  params: LoadAnalysisTemplateParams;
}
export interface DeleteAnalysisTemplateParams {
  compSearchLocalLibraryId: number;
  tenantId: number;
}
export interface DeletePsgAnalysisTemplateParams {
  compSearchGlobalLibraryId: number;
}

export const loadAnalysisTemplate = createAsyncThunk<void, LoadAnalysisTemplate, { rejectValue: Error }>(
  'pbas/compSearch/load-comp-search',
  async ({ payload, params }, { rejectWithValue }) => {
    try {
      const relativePath = `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/container/${params.containerId}/load-comp-search`;
      await httpService.request<{ data: LoadAnalysisTemplateParams }>({
        method: 'put',
        apiUrlKey: 'compSearchApiUrl',
        relativePath,
        data: payload
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteAnalysisTemplate = createAsyncThunk<void, DeleteAnalysisTemplateParams, { rejectValue: Error }>(
  'pbas/compSearch/delete-comp-search',
  async ({ compSearchLocalLibraryId, tenantId }, { rejectWithValue }) => {
    try {
      const relativePath = `/v1/saved-comp-search/${compSearchLocalLibraryId}/tenant/${tenantId}/delete-comp-search`;
      await httpService.request<void>({
        method: 'delete',
        apiUrlKey: 'compSearchApiUrl',
        relativePath
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deletePsgAnalysisTemplate = createAsyncThunk<
  void,
  DeletePsgAnalysisTemplateParams,
  { rejectValue: Error }
>('pbas/compSearch/delete-psg-comp-search', async ({ compSearchGlobalLibraryId }, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/comp-search-global-library/${compSearchGlobalLibraryId}/delete`;
    await httpService.request<void>({
      method: 'delete',
      apiUrlKey: 'compSearchApiUrl',
      relativePath
    });
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const loadPsgAnalysisTemplate = createAsyncThunk<
  void,
  CompSearchLoadPsgAnalysisTemplatePayload,
  { rejectValue: Error }
>('pbas/compSearch/comp-search-global-library/load', async (payload, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/comp-search-global-library/compsearch/${payload.compSearchId}/jurisdiction/${payload.jurisdictionId}/container/${payload.containerId}/load`;
    await httpService.request<{ data: any }>({
      method: 'put',
      apiUrlKey: 'compSearchApiUrl',
      relativePath,
      data: {
        loadCompSearchId: payload.loadCompSearchId,
        loadJurisdictionId: payload.loadJurisdictionId,
        loadContainerId: payload.loadContainerId
      }
    });
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

interface ProjectedComPoolCountResponse {
  isCompPoolAllowed: boolean;
  numCompsInPool: number;
  jurisdictionId: number;
}
interface ProjectedCompoolCount {
  isCompPoolAllowed: boolean;
  numCompsInPool: number;
}

interface ProjectedComPoolCountReduxPayload {
  compSearchId: number;
  jurisdictionId: number;
  projectedCompPoolPayload: ProjectCompPoolPayload;
}

interface ProjectedCompPoolCountWithoutCompSearchReduxPayload {
  jurisdictionId: number;
  projectedCompPoolPayload: ProjectCompPoolWithoutCompSearchPayload;
}

export const projectCompPoolCount = createAsyncThunk<
  ProjectedComPoolCountResponse,
  ProjectedComPoolCountReduxPayload,
  { rejectValue: Error }
>(
  'pbas/compSearch/project-comp-pool-count/put',
  async ({ compSearchId, jurisdictionId, projectedCompPoolPayload }, { rejectWithValue }) => {
    try {
      const relativePath = `/v1/compsearch/${compSearchId}/jurisdiction/${jurisdictionId}/comp-pool-count/project`;
      const { data } = await httpService.request<{ data: ProjectedCompoolCount }>({
        method: 'put',
        apiUrlKey: 'compSearchApiUrl',
        relativePath,
        data: projectedCompPoolPayload
      });

      return {
        jurisdictionId,
        ...data.data
      };
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const projectCompPoolCountWithoutCompSearch = createAsyncThunk<
  ProjectedComPoolCountResponse,
  ProjectedCompPoolCountWithoutCompSearchReduxPayload,
  { rejectValue: Error }
>('pbas/project-comp-pool-count/post', async ({ jurisdictionId, projectedCompPoolPayload }, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/jurisdiction/${jurisdictionId}/comp-pool-count/project`;
    const { data } = await httpService.request<{ data: ProjectedCompoolCount }>({
      method: 'post',
      apiUrlKey: 'compSearchApiUrl',
      relativePath,
      data: { ...projectedCompPoolPayload }
    });

    return {
      jurisdictionId,
      ...data.data
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updateTestedPartyFinancial = createAsyncThunk<
  { data: SheetFinancialData[]; financialTypeId: number; updatedYear: number },
  // TODO: we need to add container to payload too, and iterate every year when save
  {
    testedPartyId: number;
    financialTypeId: number;
    payload: {
      financialData: SheetFinancialData[];
      valueEnteredIn: number;
      container: Container;
      pbaId?: number | undefined;
    };
  },
  { rejectValue: Error }
>('testedParty/financial/post', async ({ testedPartyId, financialTypeId, payload }, { rejectWithValue }) => {
  try {
    const { data } = await httpService.request<{ data: SheetFinancialData[] }>({
      method: 'post',
      apiUrlKey: 'baseUrl',
      relativePath: `financial-data/${testedPartyId}/area/${financialTypeId}/type/${FinancialDataObjectTypeEnum.TESTED_PARTY}`,
      data: payload
    });

    const updatedYear = payload.financialData[0].taxYear;

    return { data: data.data, financialTypeId, updatedYear };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchFineTuningCompanies = createAsyncThunk<
  FineTuningCompanies,
  { params: FineTuneParams },
  { rejectValue: Error }
>('pba/fineTuning/signedUrl/get', async ({ params }, { rejectWithValue }) => {
  try {
    const data = await httpService.request<any>({
      method: 'get',
      apiUrlKey: 'compSearchApiUrl',
      relativePath: `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/pli/${params.pliTypeId}/pli-avg/${params.pliAvgTypeId}/comp-pool?sort=pliValue:asc`
    });

    const signedUrl = data.data.data.url;
    const response = await httpService.requestWithoutAuthHeader<FineTuningCompanies>(signedUrl);
    return response.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchRejectionReasons = createAsyncThunk<Rejections[], { params: FineTuneParams }, { rejectValue: Error }>(
  'pba/fineTuning/rejectionReasons/get',
  async ({ params }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: Rejections[] }>({
          method: 'get',
          apiUrlKey: 'compSearchApiUrl',
          relativePath: `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/pli/${params.pliTypeId}/pli-avg/${params.pliAvgTypeId}/rejection-reasons`
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFineTuningCompany = createAsyncThunk<
  FineTuningCompDetails[],
  { params: FineTuningCompanyParams },
  { rejectValue: Error }
>('pba/fineTuning/companyDetails/get', async ({ params }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: FineTuningCompDetails[] }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/pli/${params.pliTypeId}/pli-avg/${params.pliAvgTypeId}/evaluate-tax-payer?companyIds=${params.companyId}`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updateRejectionReason = createAsyncThunk<any, RejectionStatusPayload, { rejectValue: Error }>(
  'pba/fineTuning/rejectionReasons/update',
  async (params: RejectionStatusPayload, { rejectWithValue }) => {
    try {
      await httpService.request<{ data: any }>({
        method: 'put',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/compsearch/${String(params.compSearchId)}/jurisdiction/${String(
          params.jurisdictionId
        )}/pli/${String(params.pliTypeId)}/update-fine-tuning-status`,
        data: params.data
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFineTuningFinancialInformationCompany = createAsyncThunk<
  FineTuningFinancialInfo[],
  { params: FineTuningCompanyParams },
  { rejectValue: Error }
>('pba/fineTuning/financialInfo/get', async ({ params }, { rejectWithValue }) => {
  try {
    const queryPath = `companyIds=${params.companyId}`;
    const relativePath = `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/financial-info?${queryPath}`;
    return (
      await httpService.request<{ data: FineTuningFinancialInfo[] }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchFineTuningPLICompany = createAsyncThunk<
  FineTuningPLIInfo,
  { params: FineTuningCompanyParams },
  { rejectValue: Error }
>('pba/fineTuning/PLI/get', async ({ params }, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/compsearch/${params.compSearchId}/jurisdiction/${params.jurisdictionId}/source/${params.companyId}/comp-pool-plis`;
    return (
      await httpService.request<{ data: FineTuningPLIInfo }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchParentContainerCompPoolRunOnDate = createAsyncThunk<
  ParentContainerCompPoolInfo,
  { containerId: number; pbaId: number },
  { rejectValue: Error }
>('pba/container/pba/runOnDate/get', async ({ containerId, pbaId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: ParentContainerCompPoolInfo }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/container/${containerId}/pba/${pbaId}/runOnDate`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export interface GetJurisdictionRules {
  rangeMethodTypeId: number;
  mustUsePublicComps: number;
  mustUseLocalComps: number;
  filingDate: string;
  countryISO: string;
}

export interface JurisdictionRules {
  [key: number]: {
    [key: number]: GetJurisdictionRules;
  };
}

export const fetchGetJurisdictionRules = createAsyncThunk<
  GetJurisdictionRules,
  { jurisdictionId: number; taxYear: number },
  { rejectValue: Error }
>('pba/jurisdictionRules/get', async ({ jurisdictionId, taxYear }, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/compsearch/rules/${jurisdictionId}/taxyear/${taxYear}`;
    return (
      await httpService.request<{ data: GetJurisdictionRules }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const pbasSlice = createSlice({
  name: 'pbas',
  initialState,
  reducers: {
    clearPbaData: (state) => {
      state.pbas = null;
      state.oldTestedPartyFinancials = null;
      state.balanceSheetFinancials = null;
      state.incomeStatementFinancials = null;
      state.pbaJurisdiction = null;
      state.appliedBulkRejections = null;
      state.nonAppliedBulkRejections = null;
      state.additionalFinancialInfo = '';
      state.functionalAnalysisCharacteristics = {
        Functions: [],
        Risks: [],
        Assets: []
      };
      state.parentContainerCompPoolInfo = {};
      clearLoadingStates();
    },
    runCSRun: (state, action: PayloadAction<any>) => {
      const testedPartyDomicileId = state.currentTestedParty?.pba?.primaryLegalEntity?.domicile?.countryId;
      const { jurisdictionRunId } = action.payload;
      const jurisdictionId = jurisdictionRunId === -1 ? testedPartyDomicileId : jurisdictionRunId;
      state.csJurisdictionRunId = jurisdictionId;
      state.csStatus = CompSearchState.ByName.Running.Id;
    },
    doneCSRunning: (state) => {
      state.csStatus = CompSearchState.ByName.NotReady.Id;
    },
    removeCurrentPbaJurisdictions: (state) => {
      state.pbaJurisdiction = [];
    },
    updateRejectionRowStatus(state, action: PayloadAction<any>) {
      const row = state.fineTuningCompanies.data.find((comp: any) => {
        return Number(comp.sourceId) === Number(action.payload.sourceId);
      });
      if (!row) return;
      const { isAcceptedAndReviewed, isRejected } = action.payload.compStatus;

      row.compStatus = isAcceptedAndReviewed
        ? 'global.accepted_and_reviewed'
        : isRejected
        ? 'global.rejected'
        : 'global.accepted';
    },
    clearSelectedCompDetails: (state) => {
      state.selectedFineTuningCompany = undefined;
    },
    setselectedFineTuningCompany: (state, action) => {
      state.selectedFineTuningCompany = action.payload;
    },
    clearCompSearch: (state) => {
      state.initialRangeComparables = undefined;
      state.initialRangeResults = undefined;
      state.appliedBulkRejections = undefined;
      state.nonAppliedBulkRejections = undefined;
      state.initialRangeComparablesWithRejections = undefined;
      state.fineTuningCompanies = undefined;
      state.selectedFineTuningCompany = undefined;
      state.finalRangeComparables = undefined;
      state.finalRangeResults = undefined;
      state.finalRangeDiscussion = undefined;
      state.gapAnalysis = undefined;
      state.newGapAnalysis = undefined;
    },
    resetSelectedDiscussionText: (state, action: PayloadAction<{ FAType: number; name: string }>) => {
      let selectedFAState =
        action.payload.FAType === 1
          ? state.functionalAnalysisCharacteristics?.Functions
          : state.functionalAnalysisCharacteristics?.Risks;
      selectedFAState = action.payload.FAType === 3 ? state.functionalAnalysisCharacteristics?.Assets : selectedFAState;
      const currentFA = selectedFAState?.find((type) => type.characteristicName === action.payload.name);
      if (currentFA) {
        currentFA.discussion = currentFA?.functionalCharacteristicType?.discussion ?? '';
      }
    },
    resetAllAvailableManualComparableCompanies: (state) => {
      state.allAvailableManualComparablesCompanies = null;
    },
    resetFAContributionData: (state) => {
      state.functionalAnalysisCharacteristics?.Assets.forEach((asset) => {
        asset.otherParticipantContribution = false;
        asset.testedPartyContribution = false;
      });
      state.functionalAnalysisCharacteristics?.Functions.forEach((functionPerformed) => {
        functionPerformed.otherParticipantContribution = false;
        functionPerformed.testedPartyContribution = false;
      });
      state.functionalAnalysisCharacteristics?.Risks.forEach((risk) => {
        risk.otherParticipantContribution = false;
        risk.testedPartyContribution = false;
      });
    },
    clearLoadingStates: (state) => {
      state.loading = {
        fetchPBAJurisdictionDescription: {}
      };
    },
    updateFADiscussionText: (state, action) => {
      let selectedFAState =
        action.payload.FAType === 1
          ? state.functionalAnalysisCharacteristics?.Functions
          : state.functionalAnalysisCharacteristics?.Risks;
      selectedFAState = action.payload.FAType === 3 ? state.functionalAnalysisCharacteristics?.Assets : selectedFAState;
      const currentFA = selectedFAState?.find(
        (type) => type.pbaFunctionalAnalysisDataId === action.payload.pbaFunctionalAnalysisDataId
      );
      if (currentFA) {
        currentFA.discussion = action.payload.discussion;
      }
    },
    updatePbaJurisdictionCompSearchOptions: (
      state,
      action: PayloadAction<{
        jurisdictionId: number;
        dataDate?: string | null;
        chosenCountry?: PbaLocation | null;
        compPoolCount?: number | null;
        maxSicCode?: IndustrialCodeValue | null;
        minSicCode?: IndustrialCodeValue | null;
      }>
    ) => {
      const currentPba = state.currentPBA;
      if (!state.pbas) {
        state.pbas = [];
      }

      state.pbas.forEach((pba) => {
        if (!pba.compSearchJurisdictionsOptions) {
          pba.compSearchJurisdictionsOptions = {};
        }
      });
      const pba = state.pbas.find((pba) => pba.pbaId === currentPba?.pbaId);
      if (pba) {
        const { jurisdictionId, chosenCountry, compPoolCount, dataDate, maxSicCode, minSicCode } = action.payload;
        const current = pba.compSearchJurisdictionsOptions ? pba.compSearchJurisdictionsOptions[jurisdictionId] : null;

        pba.compSearchJurisdictionsOptions = {
          ...pba.compSearchJurisdictionsOptions,
          [jurisdictionId]: {
            dataDate: dataDate ?? current?.dataDate,
            chosenCountry: chosenCountry ?? current?.chosenCountry,
            compPoolCount: compPoolCount ?? current?.compPoolCount,
            minSicCode: minSicCode ?? current?.minSicCode,
            maxSicCode: maxSicCode ?? current?.maxSicCode
          }
        };
      }
    },
    clearFinancialLoadingStates: (state) => {
      state.loading = {
        ...state.loading,
        updateTestedPartyFinancial: undefined,
        fetchTestedPartyFinancials: undefined
      };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchPBAPli.pending, (state: PBAsState) => {
        state.loading.fetchPBAPli = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchPBAPli.fulfilled, (state: PBAsState, action: PayloadAction<PliData>) => {
        const { pliValues, pliAverages } = action.payload;
        state.pbaPli = { pbaPliValues: [], pliAverages: [] } as any;
        const roundedPliValues = roundPliValues(pliValues);
        const roundedPliAverages = roundPliValues(pliAverages);
        state.pbaPli!.pliValues = roundedPliValues;
        state.pbaPli!.pliAverages = roundedPliAverages;
        state.loading.fetchPBAPli = FetchLoadingStateEnum.fulfilled;
      })
      .addCase(fetchPBAPli.rejected, (state: PBAsState) => {
        state.loading.fetchPBAPli = FetchLoadingStateEnum.error;
      })
      .addCase(deletePBA.fulfilled, (state: PBAsState, action: PayloadAction<number>) => {
        const idx = (state.pbas ?? []).findIndex(({ pbaId }) => pbaId === action.payload);
        if (idx >= 0) {
          state.pbas!.splice(idx, 1);
        }
      })
      .addCase(fetchAppliedBulkRejections.fulfilled, (state: PBAsState, action: PayloadAction<BulkRejection[]>) => {
        state.appliedBulkRejections = action.payload;
      })
      .addCase(fetchNonAppliedBulkRejections.fulfilled, (state: PBAsState, action: PayloadAction<BulkRejection[]>) => {
        state.nonAppliedBulkRejections = action.payload;
      })
      .addCase(fetchPBAs.pending, (state: PBAsState) => {
        state.loading.fetchPBAs = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchPBAs.fulfilled, (state: PBAsState, action: PayloadAction<ProfitBasedAnalysis[]>) => {
        state.loading.fetchPBAs = FetchLoadingStateEnum.fulfilled;
        state.pbas = action.payload;
        if (!state.currentTestedParty) {
          state.currentTestedParty = undefined;
        }
      })
      .addCase(fetchPBAs.rejected, (state: PBAsState) => {
        state.loading.fetchPBAs = FetchLoadingStateEnum.error;
      })
      .addCase(editJurisdictionPliStatus, (state: PBAsState, action: PayloadAction<PliStatusUpdate>) => {
        const { jurisdictionId, pliIndex, exclude } = action.payload;
        if (state?.pbaJurisdiction) {
          const jurisdictions = [...state.pbaJurisdiction];
          const jurisdictionIndex = jurisdictions.findIndex(
            (jurisdiction) => jurisdiction.jurisdictionId === jurisdictionId
          );
          if (jurisdictionIndex !== -1) {
            const jurisdiction = state?.pbaJurisdiction?.[jurisdictionIndex];
            const pli = jurisdiction?.jurisdictionPli.data[pliIndex];
            pli.exclude = exclude;
            state.pbaJurisdiction = jurisdictions;
          }
        }
      })
      .addCase(fetchCurrentTestedParty.pending, (state: PBAsState) => {
        state.loading.fetchCurrentTestedParty = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchCurrentTestedParty.fulfilled, (state: PBAsState, action: PayloadAction<any>) => {
        state.loading.fetchCurrentTestedParty = FetchLoadingStateEnum.fulfilled;
        state.currentTestedParty = action.payload;
      })
      .addCase(fetchCurrentTestedParty.rejected, (state: PBAsState) => {
        state.loading.fetchCurrentTestedParty = FetchLoadingStateEnum.error;
      })
      .addCase(fetchManualComparablesCompanies.pending, (state: PBAsState) => {
        state.loading.fetchManualComparablesCompanies = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchManualComparablesCompanies.fulfilled, (state: PBAsState, action: PayloadAction<any>) => {
        if (state.currentPBA) {
          const { payload } = action;

          const manualComparablesCompaniesWithJurisdictionIds = payload.reduce((acc: any, company: any) => {
            if (acc[company.comp_pool_company_id]) {
              acc[company.comp_pool_company_id].jurisdictionIds.push(company.jurisdictionId);
            } else {
              acc[company.comp_pool_company_id] = { ...company, jurisdictionIds: [company.jurisdictionId] };
            }

            acc[company.comp_pool_company_id].id = company.sourceId;

            return acc;
          }, {});

          state.manualComparablesCompanies = Object.values(manualComparablesCompaniesWithJurisdictionIds);
        }

        state.loading.fetchManualComparablesCompanies = FetchLoadingStateEnum.fulfilled;
      })
      .addCase(fetchManualComparablesCompanies.rejected, (state: PBAsState) => {
        state.loading.fetchManualComparablesCompanies = FetchLoadingStateEnum.error;
      })
      .addCase(searchManualComparablesCompanies.fulfilled, (state: any, action: PayloadAction<any>) => {
        const allCompanies: ManualComparablesCompany[] = action.payload.map((data: any) => ({
          id: data.id,
          searchScore: data.searchScore,
          name: data.name,
          tagged: data.tagged,
          sourceType: data.sourceType,
          sourceId: data.sourceId,
          dataProvider: data.dataProvider,
          domicileName: data.domicileName,
          domicileId: data.domicileId,
          description: data.description,
          sicCode: data.sicCode,
          website: data.website,
          available: true
        }));

        state.allAvailableManualComparablesCompanies = allCompanies;
      })
      .addCase(removeManualComparablesCompany.fulfilled, (state: PBAsState, action: PayloadAction<any>) => {
        const { sourceId } = action.payload;
        if (state.manualComparablesCompanies) {
          const currentPbaCompanies: ManualComparablesCompany[] = state.manualComparablesCompanies ?? [];
          if (currentPbaCompanies) {
            const indexToRemove = currentPbaCompanies.findIndex((company) => company.sourceId === sourceId);
            if (indexToRemove >= 0) {
              currentPbaCompanies.splice(indexToRemove, 1);
              state.manualComparablesCompanies = currentPbaCompanies;
            }
          }
        }
      })
      .addCase(addManualComparablesCompany.fulfilled, (state: PBAsState, action: PayloadAction<any>) => {
        const { company } = action.payload;
        const currentPbaCompanies: ManualComparablesCompany[] = state.manualComparablesCompanies ?? [];
        currentPbaCompanies.push({
          id: company.sourceId,
          sourceId: company.sourceId,
          sourceType: company.sourceType,
          name: company.name,
          domicileId: company.domicileId || -1,
          domicileName: company.domicileName,
          jurisdictionIds: company.jurisdictionIds,
          sicCode: company.sicCode || -1,
          tagged: company.tagged || false,
          description: company.description
        });

        state.manualComparablesCompanies = currentPbaCompanies;
      })
      .addCase(updateJurisdictionsForManualComparablesCompany.pending, (state: PBAsState, action) => {
        const { sourceId } = action.meta.arg;
        state.loading.updateJurisdictionsForManualComparablesCompany = {
          ...state.loading.updateJurisdictionsForManualComparablesCompany,
          [sourceId]: FetchLoadingStateEnum.loading
        };
      })
      .addCase(updateJurisdictionsForManualComparablesCompany.fulfilled, (state: PBAsState, action) => {
        const companyToUpdate = state.manualComparablesCompanies?.find(
          (manComp) => manComp.id === action.payload.sourceId
        );
        if (companyToUpdate) {
          companyToUpdate.jurisdictionIds = action.payload.jurisdictionIds;
        }

        const { sourceId } = action.meta.arg;
        state.loading.updateJurisdictionsForManualComparablesCompany = {
          ...state.loading.updateJurisdictionsForManualComparablesCompany,
          [sourceId]: FetchLoadingStateEnum.fulfilled
        };
      })
      .addCase(updateJurisdictionsForManualComparablesCompany.rejected, (state: PBAsState, action) => {
        const { sourceId } = action.meta.arg;
        state.loading.updateJurisdictionsForManualComparablesCompany = {
          ...state.loading.updateJurisdictionsForManualComparablesCompany,
          [sourceId]: FetchLoadingStateEnum.error
        };
      })
      .addCase(currentPBA, (state: PBAsState, action: PayloadAction<ProfitBasedAnalysis>) => {
        state.currentPBA = action.payload;
      })
      .addCase(fetchTestedPartyFinancials.pending, (state: PBAsState) => {
        state.loading.fetchTestedPartyFinancials = FetchLoadingStateEnum.loading;
      })
      .addCase(
        fetchTestedPartyFinancials.fulfilled,
        (state: PBAsState, action: PayloadAction<{ data: FinancialData[]; financialTypeId: number }>) => {
          state.loading.fetchTestedPartyFinancials = FetchLoadingStateEnum.fulfilled;
          const { data, financialTypeId } = action.payload;

          if (financialTypeId === 1) {
            state.incomeStatementFinancials = data;
          } else if (financialTypeId === 2) {
            state.balanceSheetFinancials = data;
          }
        }
      )
      .addCase(fetchTestedPartyFinancials.rejected, (state: PBAsState) => {
        state.loading.fetchTestedPartyFinancials = FetchLoadingStateEnum.error;
      })
      .addCase(runCompSearch.fulfilled, (state: PBAsState, action: PayloadAction<CompSearchStatus>) => {
        state.csStatus = action.payload.compSearchState;
      })
      .addCase(fetchCompSearchStatus.fulfilled, (state: PBAsState, action: PayloadAction<CompSearchStatus>) => {
        clearLoadingStates();
        state.csStatus = action.payload.compSearchState;
      })
      .addCase(fetchPbaJurisdictions.pending, (state: PBAsState) => {
        state.loading.fetchPBAJurisdictionDetails = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchPbaJurisdictions.fulfilled, (state: PBAsState, action: PayloadAction<PbaJurisdictionPayload>) => {
        state.loading.fetchPBAJurisdictionDetails = FetchLoadingStateEnum.fulfilled;
        const jurisdictions: string[] = Object.keys(action.payload.data);
        const pbaJurisdictions = action.payload.data;
        state.pbaJurisdiction = jurisdictions.map((jurisdiction: string) => {
          return pbaJurisdictions[jurisdiction].data[0];
        });
      })
      .addCase(fetchPbaJurisdictions.rejected, (state: PBAsState) => {
        state.loading.fetchPBAJurisdictionDetails = FetchLoadingStateEnum.error;
      })
      .addCase(fetchJurisdictionDescription.pending, (state: PBAsState, action) => {
        const { jurisdictionId } = action.meta.arg;
        state.loading.fetchPBAJurisdictionDescription = state.loading.fetchPBAJurisdictionDescription ?? {};
        state.loading.fetchPBAJurisdictionDescription[jurisdictionId] = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchJurisdictionDescription.fulfilled, (state: PBAsState, action) => {
        const jurisdictionInfo = action.payload;
        const { jurisdictionId } = jurisdictionInfo;
        state.pbaJurisdiction?.forEach((jurisdiction) => {
          if (jurisdiction.jurisdictionId === jurisdictionId) {
            jurisdiction.pbaJurisdictionInfo = jurisdictionInfo;
          }
        });
        state.loading.fetchPBAJurisdictionDescription = state.loading.fetchPBAJurisdictionDescription ?? {};
        if (jurisdictionId) {
          state.loading.fetchPBAJurisdictionDescription[jurisdictionId] = FetchLoadingStateEnum.fulfilled;
        }
      })
      .addCase(fetchJurisdictionDescription.rejected, (state: PBAsState, action) => {
        const { jurisdictionId } = action.meta.arg;
        state.loading.fetchPBAJurisdictionDescription = state.loading.fetchPBAJurisdictionDescription ?? {};
        state.loading.fetchPBAJurisdictionDescription[jurisdictionId] = FetchLoadingStateEnum.error;
      })
      .addCase(fetchJurisdictionPlis.fulfilled, (state: PBAsState, action: PayloadAction<PbaJurisdictionPayload>) => {
        const jurisdictionPli = action.payload.data;
        state.pbaJurisdiction?.forEach((jurisdiction) => {
          jurisdiction.jurisdictionPli =
            jurisdiction.jurisdictionId === action.payload.jurisdictionId ? jurisdictionPli : null;
        });
        state.loading.fetchJurisdictionPlis = FetchLoadingStateEnum.fulfilled;
      })
      .addCase(fetchJurisdictionPlis.pending, (state: PBAsState) => {
        state.loading.fetchJurisdictionPlis = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchJurisdictionPlis.rejected, (state: PBAsState) => {
        state.loading.fetchJurisdictionPlis = FetchLoadingStateEnum.error;
      })
      .addCase(fetchJurisdictionPliFormats.pending, (state: PBAsState) => {
        state.loading.fetchPBAJurisdictionPLIFormats = FetchLoadingStateEnum.loading;
      })
      .addCase(
        fetchJurisdictionPliFormats.fulfilled,
        (state: PBAsState, action: PayloadAction<{ data: PbaJurisdictionPliFormat[] }>) => {
          state.loading.fetchPBAJurisdictionPLIFormats = FetchLoadingStateEnum.fulfilled;
          const jurisdictionPliFormat = action.payload.data;
          state.pbaJurisdiction?.forEach((pbaJurisdiction) => {
            const pliJurisdiction = jurisdictionPliFormat.filter(
              (jurisdiction: PbaJurisdictionPliFormat) =>
                jurisdiction.jurisdictionId === pbaJurisdiction.jurisdictionId &&
                jurisdiction.compSearchId === pbaJurisdiction.compSearchId
            );
            pbaJurisdiction.jurisdictionPliFormat = pliJurisdiction ?? null;
          });
        }
      )
      .addCase(fetchJurisdictionPliFormats.rejected, (state: PBAsState) => {
        state.loading.fetchPBAJurisdictionPLIFormats = FetchLoadingStateEnum.error;
      })
      .addCase(
        fetchInitialRangeComparablesWithRejections.fulfilled,
        (state: PBAsState, action: PayloadAction<RangeComparables>) => {
          state.initialRangeComparablesWithRejections = action.payload;
        }
      )
      .addCase(fetchInitialRangeComparables.pending, (state: PBAsState) => {
        state.loading.fetchInitialRangeComparables = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchInitialRangeComparables.fulfilled, (state: PBAsState, action: PayloadAction<RangeComparables>) => {
        state.initialRangeComparables = action.payload;
        state.loading.fetchInitialRangeComparables = FetchLoadingStateEnum.fulfilled;
      })
      .addCase(fetchInitialRangeComparables.rejected, (state: PBAsState) => {
        state.loading.fetchInitialRangeComparables = FetchLoadingStateEnum.error;
      })
      .addCase(fetchInitialRangeResults.fulfilled, (state: PBAsState, action: PayloadAction<RangeResults>) => {
        state.initialRangeResults = action.payload;
      })
      .addCase(fetchFineTuningCompanies.fulfilled, (state: PBAsState, action: PayloadAction<FineTuningCompanies>) => {
        state.fineTuningCompanies = action.payload;
      })
      .addCase(fetchRejectionReasons.pending, (state: PBAsState) => {
        state.loading.fetchRejectionReasons = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchRejectionReasons.fulfilled, (state: PBAsState, action: PayloadAction<Rejections[]>) => {
        state.rejectionReasons = action.payload;
        state.loading.fetchRejectionReasons = FetchLoadingStateEnum.fulfilled;
      })
      .addCase(fetchRejectionReasons.rejected, (state: PBAsState) => {
        state.loading.fetchRejectionReasons = FetchLoadingStateEnum.error;
      })
      .addCase(fetchFineTuningCompany.fulfilled, (state: PBAsState, action: PayloadAction<FineTuningCompDetails[]>) => {
        state.fineTuningCompaniesPending = false;
        state.selectedFineTuningCompany = action.payload[0];
      })
      .addCase(fetchFinalRangeComparables.fulfilled, (state: PBAsState, action: PayloadAction<RangeComparables>) => {
        state.finalRangeComparables = action.payload;
      })
      .addCase(fetchFinalRangeResults.fulfilled, (state: PBAsState, action: PayloadAction<RangeResults>) => {
        state.finalRangeResults = action.payload;
      })
      .addCase(fetchFinalRangeDiscussion.fulfilled, (state: PBAsState, action: PayloadAction<RangeDiscussion>) => {
        state.finalRangeDiscussion = action.payload;
      })
      .addCase(fetchGapAnalysis.fulfilled, (state: PBAsState, action: PayloadAction<GapAnalysisResponse>) => {
        state.gapAnalysis = action.payload;
      })
      .addCase(fetchNewGapAnalysis.fulfilled, (state: PBAsState, action: PayloadAction<NewGapAnalysisResponse>) => {
        state.newGapAnalysis = action.payload;
      })
      .addCase(fetchFunctionalAnalysisCharacteristics.fulfilled, (state: PBAsState, action) => {
        const { areaId } = action.meta.arg;
        if (areaId === AreaIds.AreaIdFunctions) {
          state.functionalAnalysisCharacteristics!.Functions = action.payload;
        }

        if (areaId === AreaIds.AreaIdRisks) {
          state.functionalAnalysisCharacteristics!.Risks = action.payload;
        }

        if (areaId === AreaIds.AreaIdAssets) {
          state.functionalAnalysisCharacteristics!.Assets = action.payload;
        }
      })
      .addCase(saveFinalRangeDiscussion.fulfilled, (state: PBAsState) => state)
      .addCase(
        fetchFineTuningFinancialInformationCompany.fulfilled,
        (state: PBAsState, action: PayloadAction<FineTuningFinancialInfo[]>) => {
          state.financialInfoCompany = action.payload;
        }
      )
      .addCase(fetchFineTuningPLICompany.fulfilled, (state: PBAsState, action: PayloadAction<FineTuningPLIInfo>) => {
        state.pliInfoCompany = action.payload;
      })
      .addCase(fetchTestedPartyFinancialsInfo.fulfilled, (state: PBAsState, action: PayloadAction<string>) => {
        state.additionalFinancialInfo = typeof action.payload === 'string' ? action.payload : '';
      })
      .addCase(updateTestedPartyFinancial.pending, (state: PBAsState) => {
        state.loading.updateTestedPartyFinancial = FetchLoadingStateEnum.loading;
      })
      .addCase(updateTestedPartyFinancial.fulfilled, (state: PBAsState, action) => {
        const { data, financialTypeId, updatedYear } = action.payload;
        const keyMap: { [key: number]: keyof PBAsState } = {
          1: 'incomeStatementFinancials',
          2: 'balanceSheetFinancials'
        };

        const key = keyMap[financialTypeId];
        if (!key) return;

        state[key] = state[key].filter((item: { taxYear: number }) => item.taxYear !== updatedYear);

        data.forEach((item) => {
          if (item.taxYear === updatedYear) {
            state[key].push(item);
          }
        });

        state.loading.updateTestedPartyFinancial = FetchLoadingStateEnum.fulfilled;
        state.loading.fetchTestedPartyFinancials = undefined;
        state.loading.fetchPBAPli = undefined;
        state.loading.fetchJurisdictionPlis = FetchLoadingStateEnum.reload;
      })
      .addCase(
        fetchSavedAnalysisTemplates.fulfilled,
        (state: PBAsState, action: PayloadAction<CompSearchAnalysisTemplate[]>) => {
          state.analysisTemplates = action.payload;
        }
      )
      .addCase(
        fetchSavedPsgAnalysisTemplates.fulfilled,
        (state: PBAsState, action: PayloadAction<SavedPsgAnalysisTemplates[]>) => {
          state.savedPsgAnalysisTemplates = action.payload;
        }
      )
      .addCase(fetchParentContainerCompPoolRunOnDate.pending, (state: PBAsState) => {
        state.loading.fetchParentContainerCompPoolRunOnDate = FetchLoadingStateEnum.loading;
      })
      .addCase(
        fetchParentContainerCompPoolRunOnDate.fulfilled,
        (state: PBAsState, action: PayloadAction<ParentContainerCompPoolInfo>) => {
          state.loading.fetchParentContainerCompPoolRunOnDate = FetchLoadingStateEnum.fulfilled;
          state.parentContainerCompPoolInfo = action.payload;
        }
      )
      .addCase(fetchParentContainerCompPoolRunOnDate.rejected, (state: PBAsState) => {
        state.loading.fetchParentContainerCompPoolRunOnDate = FetchLoadingStateEnum.error;
      })
      .addCase(fetchFineTuningCompany.pending, (state: PBAsState) => {
        state.fineTuningCompaniesPending = true;
      })
      .addCase(fetchFineTuningCompany.rejected, (state: PBAsState) => {
        state.fineTuningCompaniesPending = false;
      })
      .addCase(fetchJurisdictionRules.pending, (state: PBAsState) => {
        state.loading.fetchJurisdictionRules = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchJurisdictionRules.fulfilled, (state: PBAsState) => {
        state.loading.fetchJurisdictionRules = FetchLoadingStateEnum.fulfilled;
      })
      .addCase(fetchJurisdictionRules.rejected, (state: PBAsState) => {
        state.loading.fetchJurisdictionRules = FetchLoadingStateEnum.error;
      })
      .addCase(fetchGetJurisdictionRules.pending, (state: PBAsState, action) => {
        const { jurisdictionId, taxYear } = action.meta.arg;
        state.loading.fetchGetJurisdictionRules = {
          ...state.loading.fetchGetJurisdictionRules,
          [jurisdictionId]: {
            ...(state.loading.fetchGetJurisdictionRules?.[jurisdictionId] ?? {}),
            [taxYear]: FetchLoadingStateEnum.loading
          }
        };
      })
      .addCase(fetchGetJurisdictionRules.fulfilled, (state: PBAsState, action) => {
        const { jurisdictionId, taxYear } = action.meta.arg;

        state.jurisdictionRules = {
          ...state.jurisdictionRules,
          [jurisdictionId]: { ...(state.jurisdictionRules?.[jurisdictionId] ?? {}), [taxYear]: action.payload }
        };

        state.loading.fetchGetJurisdictionRules = {
          ...state.loading.fetchGetJurisdictionRules,
          [jurisdictionId]: {
            ...(state.loading.fetchGetJurisdictionRules?.[jurisdictionId] ?? {}),
            [taxYear]: FetchLoadingStateEnum.fulfilled
          }
        };
      })
      .addCase(fetchGetJurisdictionRules.rejected, (state: PBAsState, action) => {
        const { jurisdictionId, taxYear } = action.meta.arg;
        state.loading.fetchGetJurisdictionRules = {
          ...state.loading.fetchGetJurisdictionRules,
          [jurisdictionId]: {
            ...(state.loading.fetchGetJurisdictionRules?.[jurisdictionId] ?? {}),
            [taxYear]: FetchLoadingStateEnum.error
          }
        };
      })
      .addMatcher(
        (action) => action.type.match(/^pbas\/.+\/pending$/),
        (state: PBAsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^pbas\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

interface UpdatePbaTransactionParams {
  transactionId: number;
  pbaId: number;
}
export const updatePbaTransactions = createAsyncThunk<void, UpdatePbaTransactionParams, { rejectValue: Error }>(
  'pbas/createTestedParty',
  async ({ pbaId, transactionId }, { rejectWithValue }) => {
    try {
      const relativePath = `pbas/${pbaId}/transactions`;
      await httpService.request<{ data: TestedParty }>({
        method: 'put',
        apiUrlKey: 'baseUrl',
        relativePath,
        data: { transactionId }
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const {
  clearPbaData,
  runCSRun,
  doneCSRunning,
  resetFAContributionData,
  resetSelectedDiscussionText,
  resetAllAvailableManualComparableCompanies,
  removeCurrentPbaJurisdictions,
  updateRejectionRowStatus,
  clearSelectedCompDetails,
  clearCompSearch,
  clearLoadingStates,
  setselectedFineTuningCompany,
  updateFADiscussionText,
  updatePbaJurisdictionCompSearchOptions,
  clearFinancialLoadingStates
} = pbasSlice.actions;

export default pbasSlice.reducer;
